import * as React from 'react';
import { forwardRef } from 'react';
import { observer, inject } from 'mobx-react';
import { format, formatDistanceToNow } from 'date-fns';
import { Button, WithStyles, Theme, withStyles, createStyles } from '@material-ui/core';
import { Timeline, TimelineEvent, TimelineBlip } from 'react-event-timeline';
import MaterialTable from 'material-table';
import {
	FirstPage,
	LastPage,
	ChevronLeft,
	ChevronRight,
	AddBox,
	Check,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	Search,
	ViewColumn,
	SaveAlt,
	ArrowUpward,
	Remove,
	Done,
	Error,
	Person,
} from '@material-ui/icons';

import Alarms, { IAlarmGroup } from 'src/store/alarms';
import AcknowledgeAlarmsDialog from '../components/AcknowledgeAlarmsDialog';
import CreateTicketDialog from '../components/CreateTicketDialog';
import { observable, reaction, IReactionDisposer, action, computed } from 'mobx';
import { Status, TableRow } from 'src/DataTypes';

interface IAlarmsPageProps extends WithStyles<typeof styles> {
	cleared: boolean;
}

const styles = (theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			margin: '1rem',
			flexGrow: 1,
		},
		buttonsContainer: {
			marginBottom: '1rem',
		},
		button: {
			marginRight: '1rem',
		},
		tableContainer: {
			overflow: 'auto',
			display: 'flex',
			'&>div': {
				display: 'flex',
				width: '100%',
				'&>div': { display: 'flex', width: '100%', '&>div': { width: '100%' } },
			},
		},
	});

@inject('alarms')
@observer
class AlarmsPage extends React.Component<IAlarmsPageProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private acknowledgeAlarmsShown: boolean = false;
	@observable private createTicketShown: boolean = false;
	@observable private selectedAlarms: IAlarmGroup[] = [];
	@observable private tableData: TableRow<IAlarmGroup>[] = [];

	private get injected() {
		return (this.props as any) as {
			alarms: Alarms;
		};
	}

	@computed private get list() {
		const { cleared } = this.props;
		const { alarms } = this.injected;

		return cleared ? alarms.cleared : alarms.notCleared;
	}

	public componentDidMount() {
		this.disposables.push(
			reaction(
				() => this.list,
				(alarms: IAlarmGroup[]) => {
					this.tableData = alarms.map(a => {
						const existing = this.tableData.find(d => d.key === a.key);

						if (existing && existing.tableData) {
							return { ...a, tableData: existing.tableData };
						} else {
							return a;
						}
					});
				},
				{ fireImmediately: true },
			),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { alarms } = this.injected;
		const { classes } = this.props;

		return (
			<div className={classes.container}>
				<div className={classes.buttonsContainer}>
					<Button
						variant="contained"
						color="primary"
						disabled={this.selectedAlarms.length === 0}
						onClick={this.showAcknowledgeAlarms}
						className={classes.button}
					>
						Acknowledge selected alarms
					</Button>
					<Button variant="contained" color="primary" onClick={this.showCreateTicket}>
						Create ticket without alarm
					</Button>
				</div>
				<div className={classes.tableContainer}>
					<MaterialTable
						title="Alarms"
						data={this.tableData}
						isLoading={alarms.fetchStatus === Status.Loading}
						columns={[
							{
								title: 'Source',
								render: ({ site, source, controller }: IAlarmGroup) =>
									`${site ? site + ' > ' : ''}${source}${controller ? ' > ' + controller : ''}`,
							},
							{
								title: 'Alarm name',
								field: 'name',
							},
							{
								title: 'Current status',
								field: 'currentStatus',
							},
							{
								title: 'First occurred',
								field: 'firstOccurrence',
								defaultSort: 'asc',
								render: ({ firstOccurrence }: IAlarmGroup) =>
									`${format(firstOccurrence, 'dd/MM/yyyy HH:mm')} (${formatDistanceToNow(firstOccurrence)} ago)`,
							},
							{
								title: 'Alarms count',
								render: ({ occurrences }: IAlarmGroup) => occurrences.length,
							},
						]}
						options={{
							toolbar: false,
							paging: false,
							selection: true,
							showSelectAllCheckbox: false,
							rowStyle: ({ cleared }: IAlarmGroup) => ({
								backgroundColor: cleared ? 'white' : '#e57373',
							}),
							maxBodyHeight: '100%',
						}}
						onSelectionChange={(data: TableRow<IAlarmGroup>[]) => {
							this.selectedAlarms = data.map(({ tableData, ...alarm }) => alarm);
						}}
						detailPanel={(rowData: IAlarmGroup) => {
							return (
								<Timeline style={{ maxHeight: '300px', overflow: 'auto' }}>
									{rowData.occurrences.map(({ time, status, cleared }, i) => (
										<TimelineEvent
											key={i}
											title={status || 'Alarm received'}
											createdAt={format(time, 'dd/MM/yyyy HH:mm')}
											icon={cleared ? <Done /> : <Error />}
											iconColor={cleared ? '#81c784' : '#e57373'}
										/>
									))}
									<TimelineBlip title="Now" icon={<Person />} iconStyle={{ color: 'grey' }} iconColor="grey" />
								</Timeline>
							);
						}}
						icons={{
							Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
							Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
							Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
							Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
							DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
							Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
							Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
							Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
							FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
							LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
							NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
							PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
							ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
							Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
							SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
							ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
							ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
						}}
					/>
				</div>
				{this.acknowledgeAlarmsShown && (
					<AcknowledgeAlarmsDialog alarms={this.selectedAlarms} onClose={this.hideAcknowledgeAlarms} />
				)}
				{this.createTicketShown && <CreateTicketDialog onClose={this.hideCreateTicket} />}
			</div>
		);
	}

	@action.bound
	private showAcknowledgeAlarms() {
		this.acknowledgeAlarmsShown = true;
	}

	@action.bound
	private hideAcknowledgeAlarms() {
		this.acknowledgeAlarmsShown = false;
	}

	@action.bound
	private showCreateTicket() {
		this.createTicketShown = true;
	}

	@action.bound
	private hideCreateTicket() {
		this.createTicketShown = false;
	}
}

export default withStyles(styles)(AlarmsPage);
