export type NotificationTypes = 'success' | 'error' | 'warning' | 'info';

export interface INotification {
	id: number;
	message: string;
	type: NotificationTypes;
	timeout: number | null;
}

export enum Status {
	None,
	Loading,
	Error,
	Done,
	Empty,
}

export type TableRow<T> = T & {
	tableData?: {
		id: string;
	};
};
