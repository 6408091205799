import { observable, action, computed, runInAction } from 'mobx';
import { TicketStatus, IApiTicket, IAlarmApiTicketsRequest } from '@mitie/alarm-api-types';

import { Status } from 'src/DataTypes';
import { fetchTickets } from 'src/services/apiService';
import { Root } from '.';
import Ticket from './ticket';

class Tickets {
	private rootStore: Root;
	@observable private byId: { [ticketId: number]: Ticket } = {};
	@observable
	public fetchStatus: { [ticketStatus in TicketStatus]: Status } = {
		unassigned: Status.None,
		in_progress: Status.None,
		soc_closed: Status.None,
		closed: Status.None,
	};

	constructor(rootStore: Root) {
		this.rootStore = rootStore;
	}

	@computed public get list() {
		return Object.keys(this.byId).reduce((acc: Ticket[], id: any) => {
			const ticket = this.byId[id];

			acc.push(ticket);

			return acc;
		}, [] as Ticket[]);
	}

	@computed
	public get unassignedCount() {
		return this.list.filter(t => t.data && t.data.status === 'unassigned').length;
	}

	@computed
	public get inProgressCount() {
		return this.list.filter(t => t.data && t.data.status === 'in_progress').length;
	}

	@computed
	public get socClosedCount() {
		return this.list.filter(t => t.data && t.data.status === 'soc_closed').length;
	}

	@action.bound
	public updateTickets(tickets: IApiTicket[]) {
		for (const apiTicket of tickets) {
			this.byId[apiTicket.id] = new Ticket(this.rootStore, apiTicket.id, apiTicket);
		}
	}

	@action.bound
	public fetchTickets = async (statuses: TicketStatus[], otherFilters: IAlarmApiTicketsRequest = {}) => {
		for (const status of statuses) {
			this.fetchStatus[status] = Status.Loading;
		}

		try {
			const filters: IAlarmApiTicketsRequest = { statuses, ...otherFilters };

			const { tickets } = await fetchTickets(filters);

			runInAction(() => {
				this.updateTickets(tickets);
				for (const status of statuses) {
					this.fetchStatus[status] = Status.Done;
				}
			});
		} catch (e) {
			runInAction(() => {
				for (const status of statuses) {
					this.fetchStatus[status] = Status.Error;
				}
			});
			this.rootStore.notifications.addNotification('error', 'Failed to fetch tickets list from the server');
		}
	};
	public getTicketById(id: number): Ticket {
		if (!this.byId[id]) {
			this.byId[id] = new Ticket(this.rootStore, id);
		}

		return this.byId[id];
	}
}

export default Tickets;
