import * as React from 'react';
import { forwardRef } from 'react';
import { observer, inject } from 'mobx-react';
import { format, formatDistance } from 'date-fns';
import { observable, action } from 'mobx';
import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import {
	AddBox,
	Clear,
	Check,
	DeleteOutline,
	ChevronRight,
	Edit,
	SaveAlt,
	FilterList,
	FirstPage,
	LastPage,
	ChevronLeft,
	Search,
	ArrowUpward,
	Remove,
	ViewColumn,
} from '@material-ui/icons';

import SilencedAlarms, { ISilencedAlarmWithTime } from 'src/store/silencedAlarms';
import { Status } from 'src/DataTypes';
import TicketDetails from '../components/TicketDetails';

interface ISilencedAlarmsPageProps {
	className?: string;
}

@inject('silencedAlarms')
@observer
class SilencedAlarmsPage extends React.Component<ISilencedAlarmsPageProps> {
	@observable private ticketShown: boolean = false;
	@observable private ticketId: number | null = null;

	private get injected() {
		return (this.props as any) as {
			silencedAlarms: SilencedAlarms;
		};
	}

	public render() {
		const { silencedAlarms } = this.injected;
		const { className } = this.props;

		return (
			<div className={className}>
				<MaterialTable
					title="Silenced alarms"
					data={silencedAlarms.list}
					isLoading={silencedAlarms.state === Status.Loading}
					columns={[
						{
							title: 'Scope',
							render: ({ scope }: ISilencedAlarmWithTime) =>
								scope === 'site'
									? 'All alarms from site'
									: scope === 'source'
									? 'All alarms from source'
									: 'Single alarm',
						},
						{
							title: 'Source',
							render: ({ site, source, controller }: ISilencedAlarmWithTime) =>
								`${site ? site + ' > ' : ''}${source}${controller ? ' > ' + controller : ''}`,
						},
						{
							title: 'Alarm name',
							field: 'name',
						},
						{
							title: 'Silenced until',
							render: ({ to, silencedFor }: ISilencedAlarmWithTime) =>
								`${format(to, 'dd/MM/yyyy HH:mm')} (${formatDistance(0, silencedFor)})`,
						},
						{
							title: 'Actions',
							render: ({ ticket_id }: ISilencedAlarmWithTime) => (
								<Button
									variant="outlined"
									size="small"
									onClick={e => {
										this.showTicket(ticket_id);
									}}
								>
									View ticket
								</Button>
							),
						},
					]}
					options={{
						toolbar: false,
						paging: false,
						selection: false,
					}}
					icons={{
						Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
						Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
						Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
						Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
						DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
						Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
						Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
						Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
						FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
						LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
						NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
						PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
						ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
						Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
						SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
						ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
						ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
					}}
				/>
				{this.ticketShown && this.ticketId && <TicketDetails ticketId={this.ticketId} onClose={this.hideTicket} />}
			</div>
		);
	}

	@action.bound
	private showTicket(value: number) {
		this.ticketShown = true;
		this.ticketId = value;
	}

	@action.bound
	private hideTicket() {
		this.ticketShown = false;
		this.ticketId = null;
	}
}

export default SilencedAlarmsPage;
