import { action, observable, computed } from 'mobx';

class User {
	@observable public username = '';
	@observable public userId = '';
	@observable private gettingUser = false;

	@computed public get loggedIn() {
		return this.username !== '';
	}

	constructor() {
		this.getUser();
	}

	@action.bound
	public logout() {
		this.username = '';
		window.location.replace(
			`${process.env.REACT_APP_ALARM_API_URL}/auth/logout?ref=${encodeURIComponent(window.location.origin)}`,
		);
	}

	@action.bound
	private async getUser() {
		if (this.gettingUser) {
			return;
		}

		this.gettingUser = true;

		const user = await fetch(`${process.env.REACT_APP_ALARM_API_URL}/auth/me`, {
			method: 'GET',
			credentials: 'include',
		}).then(r => r.json());

		if (!user || !user.displayName) {
			window.location.replace(
				`${process.env.REACT_APP_ALARM_API_URL}/auth/login?ref=${encodeURIComponent(window.location.href)}`,
			);
			return;
		}

		this.username = user.displayName;
		this.userId = user.userId;
		this.gettingUser = false;
	}
}

export default User;
