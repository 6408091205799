import * as React from 'react';
import { AppBar, IconButton, Menu, MenuItem, Toolbar, InputBase, Typography } from '@material-ui/core';
import { withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { observer, inject } from 'mobx-react';
import { AccountCircle, Search as SearchIcon, OfflineBoltOutlined } from '@material-ui/icons';
import { observable, computed } from 'mobx';
import NavBar from './NavBar';
import Globals from 'src/store/globals';
import Routes from 'src/store/routes';
import User from 'src/store/user';

interface IHeaderBarProps extends WithStyles<typeof styles> {
	children?: React.ReactChild;
	className?: string;
}

const styles = (theme: Theme) =>
	({
		root: {
			flexGrow: 1,
		},
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginLeft: -12,
			marginRight: 20,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.15),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.25),
			},
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(1),
				width: 'auto',
			},
		},
		searchIcon: {
			width: theme.spacing(9),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit',
			width: '100%',
		},
		inputInput: {
			paddingTop: theme.spacing(1),
			paddingRight: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			paddingLeft: theme.spacing(10),
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				width: 120,
				'&:focus': {
					width: 200,
				},
			},
		},
	} as any);

@inject('routes', 'globals', 'user')
@observer
class HeaderBar extends React.Component<IHeaderBarProps> {
	@observable private UserMenuAnchorEl: HTMLElement | null = null;
	@observable private searchTerm: string = '';
	private searchInterval: NodeJS.Timeout | undefined = undefined;

	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			globals: Globals;
			user: User;
		};
	}

	@computed
	private get userMenuOpen() {
		return Boolean(this.UserMenuAnchorEl);
	}

	@computed
	private get showSearch() {
		const { routeName } = this.injected.routes;

		if (!routeName) {
			return false;
		}

		return ['unassigned', 'inProgress', 'socClosed', 'closed'].includes(routeName);
	}

	public render() {
		const { classes } = this.props;
		const { user } = this.injected;

		return (
			<AppBar>
				<Toolbar>
					<NavBar />
					<div className={classes.grow} />
					{this.showSearch && (
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								placeholder="Filter..."
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								value={this.searchTerm}
								onChange={this.searchTermChanged}
							/>
						</div>
					)}
					<div>
						{this.injected.globals.connected === false && (
							<IconButton>
								<OfflineBoltOutlined color="error" />
							</IconButton>
						)}
						<IconButton
							aria-owns={this.userMenuOpen ? 'menu-appbar' : undefined}
							aria-haspopup="true"
							onClick={this.openMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={this.UserMenuAnchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={this.userMenuOpen}
							onClose={this.closeMenu}
						>
							<Typography variant="subtitle2" style={{ padding: '0.5rem 1rem' }}>
								{user.username}
							</Typography>
							<MenuItem onClick={this.logout}>Logout</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
		);
	}

	private openMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		this.UserMenuAnchorEl = e.currentTarget;
	};

	private closeMenu = () => {
		this.UserMenuAnchorEl = null;
	};

	private logout = () => {
		this.closeMenu();
		this.injected.user.logout();
	};

	private searchTermChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;

		this.searchTerm = value;

		if (this.searchInterval) {
			clearInterval(this.searchInterval);
		}

		this.searchInterval = setTimeout(() => this.updateSearchTerm(value), 300);
	};

	private updateSearchTerm = (value: string) => {
		const { globals } = this.injected;
		globals.searchTerm = value.length > 0 ? value.toLowerCase() : null;
	};
}

export default withStyles(styles)(HeaderBar);
