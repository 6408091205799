import * as React from 'react';
import { WithStyles, Theme, withStyles, IconButton } from '@material-ui/core';
import {
	CheckCircle as CheckCircleIcon,
	Warning as WarningIcon,
	Error as ErrorIcon,
	Info as InfoIcon,
	Close as CloseIcon,
} from '@material-ui/icons';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { Snackbar as MaterialSnackbar, SnackbarContent } from '@material-ui/core';
import classNames from 'classnames';

import { NotificationTypes } from 'src/DataTypes';

interface ISnackbarProps extends WithStyles<typeof styles> {
	type: NotificationTypes;
	message: string;
	timeout: number | null;
	onClose: () => void;
	index: number;
}

const styles = (theme: Theme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.dark,
	},
	warning: {
		backgroundColor: amber[700],
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
	},
	messageIcon: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
});

const snackbarIcons = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

class Snackbar extends React.Component<ISnackbarProps> {
	public render() {
		const { type, message, timeout, classes, onClose, index } = this.props;
		const Icon = snackbarIcons[type];

		return (
			<MaterialSnackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				className={classes[type]}
				open={true}
				autoHideDuration={timeout || undefined}
				onClose={(event, reason) => {
					if (reason === 'clickaway') {
						return;
					}

					onClose();
				}}
				style={{ bottom: `${24 + index * 68}px` }}
			>
				<SnackbarContent
					className={classes[type]}
					message={
						<span className={classes.message}>
							<Icon className={classNames(classes.icon, classes.messageIcon)} />
							{message}
						</span>
					}
					action={[
						<IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
							<CloseIcon className={classes.icon} />
						</IconButton>,
					]}
				/>
			</MaterialSnackbar>
		);
	}
}

export default withStyles(styles)(Snackbar);
