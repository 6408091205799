import {
	IAlarmApiAlarmsRequest,
	IAlarmApiAlarmsResponse,
	IAlarmApiCreateTicketRequest,
	IAlarmApiTicketsRequest,
	IAlarmApiTicketsResponse,
	IAlarmApiLinkRequest,
	IAlarmApiAddNoteRequest,
	IAlarmApiNotesRequest,
	IAlarmApiNotesResponse,
	IAlarmApiUpdateTicketRequest,
	IApiTicket,
	IApiAlarmOccurrence,
	IAlarmApiSilenceRequest,
	IAlarmApiSilencedRequest,
	IAlarmApiSilencedResponse,
	IAlarmApiCreateTicketResponse,
} from '@mitie/alarm-api-types';
import * as io from 'socket.io-client';

import { get, post, patch } from './httpService';
import rootStore from '../store';
import { action } from 'mobx';

let socket: SocketIOClient.Socket;
const apiUrl = `${process.env.REACT_APP_ALARM_API_URL}`;

export function fetchAlarms(query: IAlarmApiAlarmsRequest): Promise<IAlarmApiAlarmsResponse> {
	return get(`${apiUrl}/alarms`, query);
}

export function createTicket(query: IAlarmApiCreateTicketRequest): Promise<IAlarmApiCreateTicketResponse> {
	return post(`${apiUrl}/tickets`, query);
}

export function fetchTickets(query: IAlarmApiTicketsRequest): Promise<IAlarmApiTicketsResponse> {
	return get(`${apiUrl}/tickets`, query);
}

export function fetchTicketById(id: number): Promise<IApiTicket> {
	return get(`${apiUrl}/tickets/${id}`);
}

export function linkAlarmsToTicket(query: IAlarmApiLinkRequest) {
	return post(`${apiUrl}/tickets/link`, query);
}

export function fetchNotes(query: IAlarmApiNotesRequest): Promise<IAlarmApiNotesResponse> {
	return get(`${apiUrl}/notes`, query);
}

export function createNote(query: IAlarmApiAddNoteRequest) {
	return post(`${apiUrl}/notes`, query);
}

export function updateTicket(query: IAlarmApiUpdateTicketRequest) {
	const { id, ...rest } = query;
	return patch(`${apiUrl}/tickets/${id}`, rest);
}

export function silenceAlarms(query: IAlarmApiSilenceRequest) {
	return post(`${apiUrl}/alarms/silence`, query);
}

export function fetchSilencedAlarms(query: IAlarmApiSilencedRequest = {}): Promise<IAlarmApiSilencedResponse> {
	return get(`${apiUrl}/alarms/silenced`, query);
}

export async function initWebsocket() {
	socket = io.connect(apiUrl, { transports: ['websocket'] });

	socket.on(
		'disconnect',
		action(() => {
			rootStore.globals.connected = false;
		}),
	);

	socket.on(
		'connect',
		action(() => {
			rootStore.globals.connected = true;
		}),
	);

	socket.on('unauthorized', () => socket.disconnect());

	socket.on('tickets', (tickets: IApiTicket[]) => {
		rootStore.tickets.updateTickets(tickets);
	});

	socket.on('alarms', (alarms: IApiAlarmOccurrence[]) => {
		rootStore.alarms.updateAlarms(alarms);
	});
}

export function socketId() {
	return socket.id;
}
