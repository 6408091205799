import { TicketStatus, NoteType, IApiTicket } from '@mitie/alarm-api-types';
import { observable, action, runInAction } from 'mobx';

import { Status } from 'src/DataTypes';
import * as api from 'src/services/apiService';
import { Root } from '.';
import { IAlarm, parseAlarm } from './alarms';

export interface INote {
	created_time: Date;
	created_by: string;
	text: string;
	type: NoteType;
}

export interface ITicketData {
	status: TicketStatus;
	description: string;
	created_time: Date;
	modified_time: Date;
	created_by: string;
	modified_by: string;
	owner?: string;
	maximo_ref?: string;
	alarms: IAlarm[];
	bms_links: string[];
	analytics_links: string[];
}

export default class Ticket {
	public id: number;
	private rootStore: Root;
	@observable public data?: ITicketData;
	@observable public notes?: INote[];
	@observable public ticketFetchStatus = Status.None;
	@observable public notesFetchStatus = Status.None;

	constructor(rootStore: Root, id: number, apiTicket?: IApiTicket) {
		this.rootStore = rootStore;
		this.id = id;

		if (apiTicket) {
			this.updateFromApi(apiTicket);
		}
	}

	public updateFromApi(data: IApiTicket) {
		const { notes, alarms, created_time, modified_time, ...rest } = data;
		this.data = {
			created_time: new Date(created_time),
			modified_time: new Date(modified_time),
			alarms: alarms.map(parseAlarm),
			...rest,
		};
		this.ticketFetchStatus = Status.Done;

		if (notes) {
			this.notes = notes.map(({ created_time, ...rest }) => ({
				created_time: new Date(created_time),
				...rest,
			}));
			this.notesFetchStatus = Status.Done;
		}
	}

	@action.bound
	public async fetchTicket() {
		this.ticketFetchStatus = Status.Loading;

		try {
			const data = await api.fetchTicketById(this.id);

			runInAction(() => {
				this.updateFromApi(data);
			});
		} catch (e) {
			runInAction(() => {
				this.ticketFetchStatus = Status.Error;
			});
			this.rootStore.notifications.addNotification('error', 'Failed to fetch ticket details from the server');
		}
	}

	@action.bound
	public async fetchNotes() {
		this.notesFetchStatus = Status.Loading;

		try {
			const { notes } = await api.fetchNotes({ ticketId: this.id });
			runInAction(() => {
				this.notes = notes.map(({ created_time, ...rest }) => ({
					created_time: new Date(created_time),
					...rest,
				}));
				this.notesFetchStatus = Status.Done;
			});
		} catch (e) {
			runInAction(() => {
				this.notesFetchStatus = Status.Error;
			});
			this.rootStore.notifications.addNotification('error', 'Failed to fetch ticket details from the server');
		}
	}
}
