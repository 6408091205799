import * as React from 'react';
import { forwardRef } from 'react';
import { observer, inject } from 'mobx-react';
import { format } from 'date-fns';
import { observable, reaction, IReactionDisposer, action, runInAction } from 'mobx';
import { TicketStatus, IAlarmApiTicketsRequest } from '@mitie/alarm-api-types';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core';
import {
	AddBox,
	Clear,
	Check,
	DeleteOutline,
	ChevronRight,
	Edit,
	SaveAlt,
	FilterList,
	FirstPage,
	LastPage,
	ChevronLeft,
	Search,
	ArrowUpward,
	Remove,
	ViewColumn,
	Visibility,
	ShowChart,
	Store,
} from '@material-ui/icons';
import MaterialTable from 'material-table';

import Tickets from 'src/store/tickets';
import TicketDetails from '../components/TicketDetails';
import Globals from 'src/store/globals';
import { Status, TableRow } from 'src/DataTypes';
import { ITicketData } from 'src/store/ticket';

interface ITicketsPageProps extends WithStyles<typeof styles> {
	status: TicketStatus;
}

interface ITicket extends ITicketData {
	id: number;
}

const styles = (theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			margin: '1rem',
			flexGrow: 1,
		},
		tableContainer: {
			// 	overflow: 'auto',
			// 	display: 'flex',
			// 	'&>div': {
			// 		display: 'flex',
			// 		width: '100%',
			// 		'&>div': { display: 'flex', width: '100%', '&>div': { width: '100%' } },
			// 	},
		},
		link: {
			margin: '0 8px',
			textDecoration: 'none',
		},
		linkIcon: {
			height: '12px',
		},
	});

@inject('tickets', 'globals')
@observer
class TicketsPage extends React.Component<ITicketsPageProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private ticketShown: boolean = false;
	@observable private selectedTicket: number | null = null;
	@observable private tableData: TableRow<ITicket>[] = [];
	@observable private pageSize = 0;
	// @observable private page = 0;
	private containerRef = React.createRef<HTMLDivElement>();
	private maxTickets = 100;

	public componentDidMount() {
		const { tickets } = this.injected;

		this.disposables.push(
			reaction(
				() => ({
					status: this.props.status,
					searchTerm: this.injected.globals.searchTerm,
					pageSize: this.pageSize,
				}),
				({ status, searchTerm, pageSize }) => {
					if (status === 'closed') {
						if (pageSize > 0) {
							const filters: IAlarmApiTicketsRequest = { page: 1, limit: this.maxTickets };

							if (searchTerm) {
								filters.search = searchTerm;
							}

							tickets.fetchTickets(['closed'], filters);
						}
					} else {
						if (tickets.fetchStatus[status] === Status.None) {
							tickets.fetchTickets([status]);
						}
					}
				},
				{ fireImmediately: true },
			),
			reaction(
				() => ({
					list: this.injected.tickets.list,
					searchTerm: this.injected.globals.searchTerm,
					status: this.props.status,
				}),
				({ list, searchTerm, status }) => {
					let tickets = list
						.filter(t => t.data && t.data.status === status)
						.map(({ id, data }) => {
							return { id, ...data! } as ITicket;
						});

					if (searchTerm !== null) {
						tickets = tickets.filter(ticket => {
							const serialised = JSON.stringify(ticket);
							const keep = serialised.toLowerCase().includes(searchTerm);

							return keep;
						});
					}

					runInAction(() => {
						this.tableData = tickets.map(t => {
							const existing = this.tableData.find(d => d.id === t.id);

							if (existing && existing.tableData) {
								return { ...t, tableData: existing.tableData };
							} else {
								return t;
							}
						});
					});
				},
				{ fireImmediately: true },
			),
		);

		this.resizeTable();

		window.addEventListener('resize', this.resizeTable);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
		window.removeEventListener('resize', this.resizeTable);
	}

	private get injected() {
		return (this.props as any) as {
			tickets: Tickets;
			globals: Globals;
		};
	}

	public render() {
		const { fetchStatus } = this.injected.tickets;
		const { status, classes } = this.props;

		return (
			<div className={classes.container} ref={this.containerRef}>
				<div className={classes.tableContainer}>
					<MaterialTable<ITicket>
						title="Tickets"
						data={this.tableData}
						isLoading={fetchStatus[status] === Status.Loading}
						columns={[
							{
								title: 'ID',
								field: 'id',
								render: ({ id }: ITicket) => `SOC${id}`,
								cellStyle: { paddingRight: '24px' },
							},
							{ title: 'Description', field: 'description' },
							{
								title: 'Alarms',
								render: ({ alarms }: ITicket) => `${alarms.length} alarm${alarms.length > 1 ? 's' : ''}`,
								cellStyle: { paddingRight: '24px' },
							},
							{
								title: 'Created',
								field: 'created_time',
								render: ({ created_time }: ITicket) => format(created_time, 'dd/MM/yyyy HH:mm'),
								cellStyle: { paddingRight: '24px' },
							},
							{
								title: 'Modified',
								field: 'modified_time',
								defaultSort: 'desc',
								render: ({ modified_time }: ITicket) => format(modified_time, 'dd/MM/yyyy HH:mm'),
								cellStyle: { paddingRight: '24px' },
							},
							{ title: 'Owner', field: 'owner' },
						]}
						options={{
							toolbar: false,
							paging: true,
							pageSize: this.pageSize,
							pageSizeOptions: [],
							selection: false,
							headerStyle: { paddingRight: '24px' },
							actionsColumnIndex: -1,
						}}
						// onChangePage={this.pageChanged}
						actions={[
							{
								icon: () => <Visibility />,
								tooltip: 'Show ticket',
								onClick: (event, ticket) => this.showTicket((ticket as ITicket).id),
							},
							({ analytics_links }) => ({
								icon: () => <ShowChart />,
								tooltip: 'View Analytics',
								onClick: (event, ticket) => window.open((ticket as ITicket).analytics_links[0]),
								disabled: analytics_links.length === 0,
							}),
							({ bms_links }) => ({
								icon: () => <Store />,
								tooltip: 'View BMS',
								onClick: (event, ticket) => window.open((ticket as ITicket).bms_links[0]),
								disabled: bms_links.length === 0,
							}),
						]}
						icons={{
							Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
							Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
							Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
							Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
							DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
							Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
							Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
							Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
							FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
							LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
							NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
							PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
							ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
							Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
							SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
							ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
							ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
						}}
						localization={{
							pagination: {
								labelDisplayedRows: `{from}-{to} of {count}${this.tableData.length >= this.maxTickets ? '+' : ''}`,
							},
						}}
					/>
					{this.ticketShown && this.selectedTicket && (
						<TicketDetails ticketId={this.selectedTicket} onClose={this.hideTicket} />
					)}
				</div>
			</div>
		);
	}

	@action.bound
	private showTicket(id: number) {
		this.selectedTicket = id;
		this.ticketShown = true;
	}

	@action.bound
	private hideTicket() {
		this.selectedTicket = null;
		this.ticketShown = false;
	}

	@action.bound
	private resizeTable() {
		if (!this.containerRef.current) {
			return;
		}

		const containerHeight = this.containerRef.current.clientHeight;
		const rowHeight = 53;
		const headerHeight = 56;
		const footerHeight = 53;
		const pageSize = Math.floor((containerHeight - headerHeight - footerHeight) / rowHeight);

		if (pageSize > 0) {
			this.pageSize = pageSize;
		}
	}

	// private pageChanged = (page: number) => {
	// 	this.page = page;
	// };
}

export default withStyles(styles)(TicketsPage);
