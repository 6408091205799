import * as React from 'react';
import { forwardRef } from 'react';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	TextField,
	Button,
	WithStyles,
	createStyles,
	Theme,
	withStyles,
	FormControlLabel,
	Checkbox,
	DialogContent,
	Dialog,
	DialogTitle,
	Tabs,
	Tab,
	Badge,
	Paper,
	Radio,
} from '@material-ui/core';
import {
	ExpandMore,
	AddBox,
	Clear,
	Check,
	DeleteOutline,
	ChevronRight,
	Edit,
	SaveAlt,
	FilterList,
	FirstPage,
	LastPage,
	ChevronLeft,
	Search,
	ArrowUpward,
	Remove,
	ViewColumn,
} from '@material-ui/icons';
import { format, formatDistanceToNow } from 'date-fns';
import { IAlarmApiUpdateTicketRequest, IAlarmApiLinkRequest, TicketStatus } from '@mitie/alarm-api-types';
import MaterialTable from 'material-table';

import Alarms, { IAlarmGroup } from 'src/store/alarms';
import { createTicket, fetchTickets, updateTicket, linkAlarmsToTicket } from 'src/services/apiService';
import NotificationStore from 'src/store/notifications';
import { ticketTextStatus } from 'src/utils';

interface IAcknowledgeAlarmsProps extends WithStyles<typeof styles> {
	alarms: IAlarmGroup[];
	onClose: () => void;
}

interface IRelatedTicket {
	id: number;
	status: TicketStatus;
	created_time: Date;
	modified_time: Date;
	alarmsCount: number;
}

const styles = (theme: Theme) =>
	createStyles({
		tableContainer: {
			'&>div': {
				width: '100%',
			},
		},
		badge: {
			padding: `0 ${theme.spacing(2)}px`,
		},
		background: {
			backgroundColor: theme.palette.background.default,
		},
		tabsContainer: {
			marginTop: '1rem',
		},
		newTicketContainer: {
			padding: '1rem 2rem',
		},
	});

@inject('alarms', 'notifications')
@observer
class AcknowledgeAlarms extends React.Component<IAcknowledgeAlarmsProps> {
	@observable private description = '';
	@observable private assignToSelf = false;
	@observable private relatedTickets: IRelatedTicket[] = [];
	@observable private ticketId: null | number = null;
	@observable private submitting = false;
	@observable private maximoRef = '';
	@observable private selectedTab: 'new' | 'link' = 'new';
	@observable private selectedTicketId: number | null = null;
	@observable private linkComment: string = '';

	private get injected() {
		return (this.props as any) as {
			alarms: Alarms;
			notifications: NotificationStore;
		};
	}

	public async componentDidMount() {
		if (this.siteNames.length === 1) {
			const { tickets } = await fetchTickets({
				statuses: ['unassigned', 'in_progress', 'soc_closed'],
				site_name: this.siteNames[0],
			});

			this.relatedTickets = tickets.map(({ id, description, status, alarms, created_time, modified_time }) => ({
				id,
				status,
				alarmsCount: alarms.length,
				created_time: new Date(created_time),
				modified_time: new Date(modified_time),
				description,
			}));
		} else {
			this.relatedTickets = [];
		}
	}

	@computed
	private get alarmIds() {
		const { alarms } = this.props;

		return alarms.reduce((acc: number[], group) => {
			acc.push(...group.occurrences.map(a => a.id));
			return acc;
		}, []);
	}

	@computed
	private get canSubmit() {
		return this.description.length > 0;
	}

	@computed
	private get siteNames() {
		const { alarms } = this.props;

		const sites = alarms.reduce((acc: { [siteName: string]: true }, cur) => {
			if (cur.site) {
				acc[cur.site] = true;
			}

			return acc;
		}, {});

		return Object.keys(sites);
	}

	@computed
	private get selectedTicket() {
		if (this.selectedTicketId === null) {
			return undefined;
		}

		return this.relatedTickets.find(ticket => ticket.id === this.selectedTicketId);
	}

	@computed
	private get canLink() {
		if (!this.selectedTicket) {
			return false;
		}

		return this.selectedTicket.status !== 'soc_closed' || this.linkComment.length > 0;
	}

	public render() {
		const { alarms, classes, onClose } = this.props;
		if (this.props.alarms.length === 0) {
			return <div>No alarm selected</div>;
		}

		return (
			<Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth={true}>
				<DialogTitle className={classes.background}>
					{this.ticketId !== null ? `Ticket details: SOC${this.ticketId}` : 'Create ticket'}
				</DialogTitle>
				<DialogContent className={classes.background}>
					<ExpansionPanel>
						<ExpansionPanelSummary expandIcon={<ExpandMore />}>{`${alarms.length} alarm${
							alarms.length > 1 ? 's' : ''
						} selected`}</ExpansionPanelSummary>
						<ExpansionPanelDetails className={classes.tableContainer}>
							<MaterialTable
								title="Alarms"
								data={alarms}
								columns={[
									{
										title: 'Source',
										render: ({ site, source, controller }: IAlarmGroup) =>
											`${site ? site + ' > ' : ''}${source}${controller ? ' > ' + controller : ''}`,
									},
									{
										title: 'Alarm name',
										field: 'name',
										defaultSort: 'asc',
									},
									{
										title: 'Current status',
										field: 'currentStatus',
									},
									{
										title: 'First occurred',
										field: 'firstOccurrence',
										defaultSort: 'asc',
										render: ({ firstOccurrence }: IAlarmGroup) =>
											`${format(firstOccurrence, 'dd/MM/yyyy HH:mm')} (${formatDistanceToNow(firstOccurrence)} ago)`,
									},
									{
										title: 'Alarms count',
										render: ({ occurrences }: IAlarmGroup) => occurrences.length,
									},
								]}
								options={{
									toolbar: false,
									pageSize: 5,
									paging: alarms.length > 5,
								}}
								icons={{
									Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
									Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
									Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
									Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
									DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
									Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
									Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
									Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
									FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
									LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
									NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
									PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
									ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
									Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
									SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
									ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
									ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
								}}
							/>
						</ExpansionPanelDetails>
					</ExpansionPanel>
					<Paper square>
						<Tabs
							value={this.selectedTab}
							onChange={(event, value) => (this.selectedTab = value)}
							indicatorColor="primary"
							textColor="primary"
							className={classes.tabsContainer}
						>
							<Tab label="New ticket" value="new" />
							<Tab
								disabled={this.relatedTickets.length === 0}
								value="link"
								label={
									<Badge className={classes.badge} badgeContent={this.relatedTickets.length} color="secondary">
										Link to existing ticket
									</Badge>
								}
							/>
						</Tabs>
					</Paper>
					{this.selectedTab === 'new' ? (
						<Paper square className={classes.newTicketContainer}>
							<div style={{ display: 'flex', flexGrow: 1 }}>
								<TextField
									label="Description"
									required={true}
									value={this.description}
									onChange={e => (this.description = e.currentTarget.value)}
									style={{ flex: 1 }}
									disabled={this.ticketId !== null}
								/>

								<Button
									variant="contained"
									color="primary"
									disabled={!this.canSubmit || this.submitting || this.ticketId !== null}
									onClick={this.createTicket}
									style={{ marginLeft: '2rem' }}
								>
									Create ticket
								</Button>
							</div>
							{this.ticketId !== null && (
								<div style={{ display: 'flex', flexGrow: 1, marginTop: '2rem' }}>
									<TextField
										label="Maximo work order reference"
										required={true}
										value={this.maximoRef}
										onChange={e => (this.maximoRef = e.currentTarget.value)}
										style={{ flex: 1 }}
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={this.assignToSelf}
												onChange={e => (this.assignToSelf = e.currentTarget.checked)}
											/>
										}
										label="Assign ticket to yourself"
									/>
									<Button
										variant="contained"
										color="primary"
										disabled={!this.maximoRef || this.submitting}
										onClick={this.updateTicket}
										style={{ marginLeft: '2rem' }}
									>
										Save ticket
									</Button>
								</div>
							)}
						</Paper>
					) : (
						<Paper square>
							<MaterialTable<IRelatedTicket>
								title="Related tickets"
								data={this.relatedTickets}
								columns={[
									{
										title: '',
										render: ({ id }) => (
											<Radio checked={this.selectedTicketId === id} onClick={() => (this.selectedTicketId = id)} />
										),
										cellStyle: { paddingRight: '24px' },
									},
									{
										title: 'ID',
										render: ({ id }) => `SOC${id}`,
										cellStyle: { paddingRight: '24px' },
									},
									{ title: 'Description', field: 'description' },
									{
										title: 'Alarms count',
										render: ({ alarmsCount }) => `${alarmsCount}`,
										cellStyle: { paddingRight: '24px' },
									},
									{
										title: 'Created',
										render: ({ created_time }) => (created_time ? format(created_time, 'dd/MM/yyyy HH:mm') : null),
										cellStyle: { paddingRight: '24px' },
									},
									{
										title: 'Modified',
										render: ({ modified_time }) => (modified_time ? format(modified_time, 'dd/MM/yyyy HH:mm') : null),
										cellStyle: { paddingRight: '24px' },
									},
									{
										title: 'Owner',
										field: 'owner',
										cellStyle: { paddingRight: '24px' },
									},
									{
										title: 'Status',
										render: ({ status }) => ticketTextStatus(status),
									},
								]}
								options={{
									toolbar: false,
									pageSize: 5,
									paging: this.relatedTickets.length > 5,
									headerStyle: { paddingRight: '24px' },
								}}
								icons={{
									Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
									Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
									Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
									Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
									DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
									Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
									Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
									Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
									FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
									LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
									NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
									PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
									ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
									Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
									SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
									ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
									ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
								}}
							/>
							{this.selectedTicketId && this.selectedTicket && (
								<div style={{ display: 'flex', padding: '1rem 2rem' }}>
									<TextField
										label="Comment"
										value={this.linkComment}
										onChange={e => (this.linkComment = e.currentTarget.value)}
										style={{ flex: 1, marginRight: '2rem' }}
										disabled={this.ticketId !== null}
									/>
									<Button
										variant="contained"
										color="primary"
										disabled={this.submitting || !this.canLink}
										onClick={() => {
											if (this.selectedTicketId) {
												this.linkToTicket(this.selectedTicketId);
											}
										}}
									>
										{`Link alarms to ticket SOC${this.selectedTicketId}`}
									</Button>
								</div>
							)}
						</Paper>
					)}
				</DialogContent>
			</Dialog>
		);
	}

	private createTicket = async () => {
		try {
			this.submitting = true;
			const { ticket_id } = await createTicket({
				status: 'unassigned',
				description: this.description,
				alarmIds: this.alarmIds,
			});
			this.ticketId = ticket_id;
			this.injected.notifications.addNotification('success', 'Ticket created');
		} catch (e) {
			this.injected.notifications.addNotification('error', 'Failed to create ticket');
		}

		this.submitting = false;
	};

	private updateTicket = async () => {
		if (!this.ticketId) {
			return;
		}

		const { onClose } = this.props;
		const updatedFields: IAlarmApiUpdateTicketRequest = {
			id: this.ticketId,
			maximo_ref: this.maximoRef,
		};

		if (this.assignToSelf) {
			updatedFields.status = 'in_progress';
		}

		try {
			this.submitting = true;
			await updateTicket(updatedFields);
			onClose();
			this.injected.notifications.addNotification('success', 'Ticket updated');
		} catch (e) {
			this.injected.notifications.addNotification('error', 'Failed to update ticket');
			this.submitting = false;
		}
	};

	private linkToTicket = async (ticketId: number) => {
		const { onClose } = this.props;

		try {
			this.submitting = true;

			const request: IAlarmApiLinkRequest = { alarmIds: this.alarmIds, ticketId };

			if (this.linkComment.length > 0) {
				request.comment = this.linkComment;
			}

			await linkAlarmsToTicket(request);
			this.injected.notifications.addNotification('success', 'Ticket updated');
			onClose();
		} catch (e) {
			this.injected.notifications.addNotification('error', 'Failed to update ticket');
			this.submitting = false;
		}
	};
}

export default withStyles(styles)(AcknowledgeAlarms);
