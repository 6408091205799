import * as React from 'react';
import App from './App';
import { Provider as MobxProvider } from 'mobx-react';
import mobxStore from './store';

// import { configure } from 'mobx';
// configure({enforceActions: "observed"});

const AppContainer = () => (
	<MobxProvider {...mobxStore}>
		<App />
	</MobxProvider>
);

export default AppContainer;
