import { observable, onBecomeObserved, action, onBecomeUnobserved, computed } from 'mobx';
import { Status } from 'src/DataTypes';
import { fetchAlarms } from 'src/services/apiService';
import { Root } from './';
import { differenceInMilliseconds } from 'date-fns';
import { AlarmSourceType, AlarmHandlerStatus, IApiAlarmOccurrence } from '@mitie/alarm-api-types';

export interface IAlarm {
	id: number;
	source: AlarmSourceType;
	alarm_data: string;
	time: Date;
	description?: string;
	sla_minutes?: number;
	name?: string;
	site?: string;
	controller?: string;
	asset?: string;
	ticket_id?: number;
	status: string;
	cleared: boolean;
	alarmHandlerStatus: AlarmHandlerStatus;
	created_time: Date;
	modified_time: Date;
	timeSinceOccurrence: number;
}

export interface IAlarmGroup {
	key: string;
	source: AlarmSourceType;
	name?: string;
	site?: string;
	controller?: string;
	currentStatus: string;
	cleared: boolean;
	lastOccurrence: Date;
	firstOccurrence: Date;
	occurrences: IAlarm[];
}

class Alarms {
	private rootStore: Root;
	@observable public byId: { [id: number]: IAlarm } = {};
	private refreshInterval: NodeJS.Timeout | null = null;
	@observable public fetchStatus: Status = Status.None;

	constructor(rootStore: Root) {
		this.rootStore = rootStore;

		onBecomeObserved(this, 'list', this.fetchAlarms);
		onBecomeObserved(this, 'list', this.startUpdating);
		onBecomeUnobserved(this, 'list', this.stopUpdating);
	}

	@computed
	private get list() {
		return Object.keys(this.byId).reduce(
			(acc: { byId: { [key: string]: IAlarmGroup }; list: IAlarmGroup[] }, key) => {
				const alarm = this.byId[(key as any) as number];

				if (alarm.alarmHandlerStatus === 'new') {
					const key = `${alarm.site}-${alarm.source}-${alarm.controller}-${alarm.name}`;
					const group = acc.byId[key];

					if (group === undefined) {
						const newGroup = {
							key,
							source: alarm.source,
							name: alarm.name,
							site: alarm.site,
							controller: alarm.controller,
							currentStatus: alarm.status,
							cleared: alarm.cleared,
							firstOccurrence: alarm.time,
							lastOccurrence: alarm.time,
							occurrences: [alarm],
						};

						acc.byId[key] = newGroup;
						acc.list.push(newGroup);
					} else {
						if (alarm.time.getTime() < group.firstOccurrence.getTime()) {
							group.firstOccurrence = alarm.time;
						}

						if (alarm.time.getTime() > group.lastOccurrence.getTime()) {
							group.lastOccurrence = alarm.time;
							group.currentStatus = alarm.status;
							group.cleared = alarm.cleared;
						}

						group.occurrences.push(alarm);
						group.occurrences.sort((a, b) => a.time.getTime() - b.time.getTime());
					}
				}

				return acc;
			},
			{ byId: {}, list: [] },
		).list;
	}

	@computed
	public get notCleared() {
		return this.list.filter(a => !a.cleared);
	}

	@computed
	public get cleared() {
		return this.list.filter(a => a.cleared);
	}

	@action.bound
	public updateAlarms(alarms: IApiAlarmOccurrence[]) {
		for (const alarm of alarms) {
			this.byId[alarm.id] = parseAlarm(alarm);
		}
	}

	private startUpdating = () => {
		this.refreshInterval = setInterval(() => this.refreshTimes(), 60000);
	};

	private stopUpdating = () => {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval);
		}
	};

	@action.bound
	private refreshTimes() {
		const now = new Date();

		for (const id of Object.keys(this.byId)) {
			const alarm = this.byId[(id as any) as number];

			this.byId[(id as any) as number] = {
				...alarm,
				timeSinceOccurrence: differenceInMilliseconds(now, alarm.time),
			};
		}
	}

	private fetchAlarms = async () => {
		this.setState(Status.Loading);

		try {
			const { alarms } = await fetchAlarms({ alarmHandlerStatus: 'new' });
			this.updateAlarms(alarms);

			this.setState(Status.Done);
		} catch (e) {
			this.setState(Status.Error);
			this.rootStore.notifications.addNotification('error', 'Failed to fetch alarms list from the server');
		}
	};

	@action.bound
	private setState(state: Status) {
		this.fetchStatus = state;
	}
}

export function parseAlarm({ time, modified_time, created_time, ...rest }: IApiAlarmOccurrence) {
	const now = new Date();
	const timeDate = new Date(time);

	return {
		...rest,
		time: timeDate,
		modified_time: new Date(modified_time),
		created_time: new Date(created_time),
		timeSinceOccurrence: differenceInMilliseconds(now, timeDate),
	};
}

export default Alarms;
