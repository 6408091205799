import { observable, action } from 'mobx';
import { INotification, NotificationTypes } from '../DataTypes';

class NotificationStore {
	@observable
	public notifications: INotification[] = [];

	@action
	public addNotification = (type: NotificationTypes, message?: string) => {
		this.notifications.push({
			id: Date.now(),
			message: message ? message : 'Sorry but something has gone wrong. Please try again.',
			type,
			timeout: type === 'error' ? null : 5000,
		});
	};

	@action
	public deleteNotification = (id: number) => {
		this.notifications.splice(this.notifications.findIndex(arrNotification => arrNotification.id === id), 1);
	};
}

export default NotificationStore;
