import * as React from 'react';
import { forwardRef } from 'react';
import { observer, inject } from 'mobx-react';
import {
	ExpansionPanelDetails,
	ExpansionPanel,
	ExpansionPanelSummary,
	Button,
	TextField,
	Paper,
	Typography,
	WithStyles,
	createStyles,
	Theme,
	withStyles,
	CircularProgress,
	DialogContent,
	Dialog,
	DialogTitle,
} from '@material-ui/core';
import {
	ExpandMore,
	FirstPage,
	LastPage,
	ChevronLeft,
	ChevronRight,
	AddBox,
	Check,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	Search,
	ViewColumn,
	SaveAlt,
	ArrowUpward,
	Remove,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { observable, computed, action, IReactionDisposer, autorun } from 'mobx';
import MaterialTable from 'material-table';
import classNames from 'classnames';

import NotificationStore from 'src/store/notifications';
import { createNote, updateTicket } from 'src/services/apiService';
import Tickets from 'src/store/tickets';
import { IAlarmApiUpdateTicketRequest } from '@mitie/alarm-api-types';
import { Status } from 'src/DataTypes';
import { IAlarm } from 'src/store/alarms';
import User from 'src/store/user';

interface ITicketDetailsProps extends WithStyles<typeof styles> {
	ticketId: number;
	onClose: () => void;
	className?: string;
}

const styles = (theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
		},
		notes: {
			overflowY: 'auto',
			margin: '1rem 0',
			'& >div:first-child': {
				marginTop: '0 !important',
			},
			'& >div:last-child': {
				marginBottom: '0 !important',
			},
		},
		tableContainer: {
			'&>div': {
				width: '100%',
			},
		},
		formButton: {
			marginLeft: '2rem',
			marginTop: '1rem',
		},
		background: {
			backgroundColor: theme.palette.background.default,
		},
	});

@inject('notifications', 'tickets', 'user')
@observer
class TicketDetails extends React.Component<ITicketDetailsProps> {
	private disposables: IReactionDisposer[] = [];
	@observable private newComment: string = '';
	@observable private description: string = '';
	@observable private maximoRef: string = '';
	@observable private submitting: boolean = false;

	private get injected() {
		return (this.props as any) as {
			notifications: NotificationStore;
			tickets: Tickets;
			user: User;
		};
	}

	@computed
	private get ticket() {
		return this.injected.tickets.getTicketById(this.props.ticketId);
	}

	@computed
	private get canSave() {
		if (!this.ticket.data) {
			return false;
		}

		return (
			this.description.length > 0 &&
			(this.description !== this.ticket.data.description || this.maximoRef !== this.ticket.data.maximo_ref)
		);
	}

	@computed
	private get canComment() {
		const { userId } = this.injected.user;
		return this.ticket.data && (!this.ticket.data.owner || this.ticket.data.owner === userId);
	}

	@computed
	private get canSubmit() {
		return this.newComment.length > 0 && !this.submitting;
	}

	@computed
	private get canAssign() {
		return this.ticket.data && this.ticket.data.maximo_ref && !this.submitting;
	}

	public async componentDidMount() {
		if (this.ticket.ticketFetchStatus === Status.None) {
			this.ticket.fetchTicket();
		}

		if (this.ticket.notesFetchStatus === Status.None) {
			this.ticket.fetchNotes();
		}

		this.disposables.push(
			autorun(() => {
				if (!this.ticket.data) {
					return;
				}

				this.description = this.ticket.data.description;
				this.maximoRef = this.ticket.data.maximo_ref ? this.ticket.data.maximo_ref : '';
			}),
			autorun(() => {
				if (!this.ticket.data) {
					return;
				}
			}),
		);
	}

	public componentWillUnmount() {
		this.disposables.forEach(d => d());
	}

	public render() {
		const { classes, onClose } = this.props;

		return (
			<Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth={true}>
				<DialogTitle className={classes.background}>{`Ticket details: SOC${this.ticket.id}`}</DialogTitle>
				<DialogContent className={classNames(classes.container, classes.background)}>
					{this.ticket.ticketFetchStatus === Status.Done && this.ticket.data && (
						<React.Fragment>
							<Paper style={{ padding: '1rem 1.5rem', margin: '1rem 0', display: 'flex' }}>
								<TextField
									label="Ticket name"
									value={this.description}
									onChange={e => this.setDescription(e.currentTarget.value)}
									style={{ flex: 1, marginRight: '2rem' }}
									error={this.description.length === 0}
								/>
								<TextField
									label="Maximo work order reference"
									value={this.maximoRef}
									onChange={e => this.setMaximoRef(e.currentTarget.value)}
									style={{ flex: 1 }}
								/>
								<Button
									variant="contained"
									color="primary"
									disabled={!this.canSave}
									onClick={this.updateTicket}
									className={classes.formButton}
									type="submit"
								>
									Save
								</Button>
							</Paper>
							<ExpansionPanel>
								<ExpansionPanelSummary expandIcon={<ExpandMore />}>{`${this.ticket.data.alarms.length} alarm${
									this.ticket.data.alarms.length > 1 ? 's' : ''
								} in this ticket`}</ExpansionPanelSummary>
								<ExpansionPanelDetails className={classes.tableContainer}>
									<MaterialTable<IAlarm>
										title="Alarms"
										data={this.ticket.data.alarms}
										columns={[
											{
												title: 'Source',
												render: ({ site, source, controller }) =>
													`${site ? site + ' > ' : ''}${source}${controller ? ' > ' + controller : ''}`,
											},
											{
												title: 'Alarm name',
												field: 'name',
												defaultSort: 'asc',
											},
											{
												title: 'Time occurred',
												field: 'time_occurred',
												render: ({ time }) => (time ? format(time, 'dd/MM/yyyy HH:mm') : null),
											},
										]}
										options={{
											toolbar: false,
											pageSize: 5,
											paging: this.ticket.data.alarms.length > 5,
										}}
										icons={{
											Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
											Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
											Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
											Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
											DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
											Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
											Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
											Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
											FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
											LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
											NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
											PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
											ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
											Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
											SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
											ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
											ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
										}}
									/>
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<div className={classes.notes}>
								{this.ticket.notes &&
									this.ticket.notes.map((note, i) =>
										note.type === 'comment' ? (
											<Paper key={i} style={{ padding: '0.5rem 1.5rem', margin: '1rem 0' }}>
												<Typography variant="caption" component="p">{`${note.created_by} - ${format(
													note.created_time,
													'dd/MM/yyyy HH:mm:ss',
												)}`}</Typography>
												<Typography variant="body1" component="p" style={{ paddingTop: '0.5rem' }}>
													{this.formatText(note.text)}
												</Typography>
											</Paper>
										) : (
											<Paper key={i} style={{ padding: '0.5rem 1.5rem', margin: '1rem 0' }}>
												<Typography variant="caption">{`${note.created_by} - ${format(
													note.created_time,
													'dd/MM/yyyy HH:mm:ss',
												)}`}</Typography>
												<Typography variant="caption">{this.formatText(note.text)}</Typography>
											</Paper>
										),
									)}
							</div>
							{this.ticket.data.status !== 'closed' && this.canComment && (
								<Paper style={{ padding: '1rem 1.5rem', margin: '1rem 0', display: 'flex' }}>
									<TextField
										label="Add a comment"
										value={this.newComment}
										onChange={e => this.setComment(e.currentTarget.value)}
										style={{ flex: 1 }}
										autoFocus={true}
									/>
									<Button
										variant="contained"
										color="primary"
										disabled={!this.canSubmit}
										onClick={this.createNote}
										className={classes.formButton}
									>
										Add comment
									</Button>
									{this.ticket.data.status === 'unassigned' && (
										<Button
											variant="contained"
											color="secondary"
											disabled={!this.canAssign}
											onClick={this.acceptTicket}
											className={classes.formButton}
										>
											Accept ticket
										</Button>
									)}
									{this.ticket.data.status === 'in_progress' && (
										<Button
											variant="contained"
											color="secondary"
											disabled={this.submitting || !this.newComment}
											onClick={this.socCloseTicket}
											className={classes.formButton}
										>
											Change to 'SOC closed'
										</Button>
									)}
									{(this.ticket.data.status === 'in_progress' || this.ticket.data.status === 'soc_closed') && (
										<Button
											variant="contained"
											color="secondary"
											disabled={this.submitting || !this.newComment}
											onClick={this.closeTicket}
											className={classes.formButton}
										>
											Close ticket
										</Button>
									)}
								</Paper>
							)}
						</React.Fragment>
					)}
					{this.ticket.ticketFetchStatus === Status.Loading && <CircularProgress size={24} />}
				</DialogContent>
			</Dialog>
		);
	}

	private createNote = async () => {
		if (this.canSubmit) {
			const { ticketId } = this.props;

			try {
				this.setSubmitting(true);
				await createNote({ ticketId, text: this.newComment });
				this.setComment('');
				this.injected.notifications.addNotification('success', 'Comment saved');
			} catch (e) {
				this.injected.notifications.addNotification('error', 'Failed to add comment to ticket');
			}

			this.setSubmitting(false);
		}
	};

	private updateTicket = async () => {
		if (this.canSave && this.ticket.data) {
			const { ticketId } = this.props;

			try {
				this.setSubmitting(true);

				const changes: IAlarmApiUpdateTicketRequest = { id: ticketId };

				if (this.description !== this.ticket.data.description) {
					changes.description = this.description;
				}

				if (this.maximoRef !== this.ticket.data.maximo_ref) {
					changes.maximo_ref = this.maximoRef;
				}

				await updateTicket(changes);
				this.injected.notifications.addNotification('success', 'Ticket updated');
			} catch (e) {
				this.injected.notifications.addNotification('error', 'Failed to update the ticket');
			}

			this.setSubmitting(false);
		}
	};

	private closeTicket = async () => {
		const { ticketId } = this.props;

		try {
			this.setSubmitting(true);
			await updateTicket({ id: ticketId, status: 'closed', comment: this.newComment });
			this.injected.notifications.addNotification('success', 'Ticket updated');
		} catch (e) {
			this.injected.notifications.addNotification('error', 'Failed to update the ticket');
		}

		this.setSubmitting(false);
	};

	private acceptTicket = async () => {
		const { ticketId } = this.props;

		try {
			this.setSubmitting(true);
			await updateTicket({ id: ticketId, status: 'in_progress', comment: this.newComment });
			this.injected.notifications.addNotification('success', 'Ticket updated');
		} catch (e) {
			this.injected.notifications.addNotification('error', 'Failed to update the ticket');
		}

		this.setSubmitting(false);
	};

	private socCloseTicket = async () => {
		const { ticketId } = this.props;

		try {
			this.setSubmitting(true);
			await updateTicket({ id: ticketId, status: 'soc_closed', comment: this.newComment });
			this.injected.notifications.addNotification('success', 'Ticket updated');
		} catch (e) {
			this.injected.notifications.addNotification('error', 'Failed to update the ticket');
		}

		this.setSubmitting(false);
	};

	@action.bound
	private setSubmitting(value: boolean) {
		this.submitting = value;
	}

	@action.bound
	private setComment(text: string) {
		this.newComment = text;
	}

	@action.bound
	private setDescription(text: string) {
		this.description = text;
	}

	@action.bound
	private setMaximoRef(text: string) {
		this.maximoRef = text;
	}

	private formatText(text: string) {
		return text.split('\n').map((item, key) => {
			return (
				<React.Fragment key={key}>
					{item}
					<br />
				</React.Fragment>
			);
		});
	}
}

export default withStyles(styles)(TicketDetails);
