import Routes from './routes';
import Notifications from './notifications';
import Alarms from './alarms';
import SilencedAlarms from './silencedAlarms';
import Tickets from './tickets';
import Globals from './globals';
import User from './user';

export class Root {
	public notifications = new Notifications();
	public routes = new Routes();
	public alarms = new Alarms(this);
	public silencedAlarms = new SilencedAlarms(this);
	public tickets = new Tickets(this);
	public globals = new Globals();
	public user = new User();
}

const stores = new Root();

export default stores;
