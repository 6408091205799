import * as React from 'react';
import { State, Options } from 'router5';

import RouteStore, { IFilters } from '../store/routes';
import Alarms from 'src/pages/Alarms';
import Tickets from 'src/pages/Tickets';
import SilencedAlarms from 'src/pages/SilencedAlarms';

export interface ILinkData {
	name?: string;
	params?: IFilters;
}

export interface IRoute {
	component?: (next?: Record<string, any>) => any;
	activate?: (store: RouteStore, current?: Record<string, any>, prev?: State) => void;
	deactivate?: (store: RouteStore, current?: Record<string, any>, next?: State) => void;
	path: string;
	defaultParams?: Record<string, any>;
	forwardTo?: string;
	name?: string;
	label?: string;
}

export interface IRoutes {
	[name: string]: IRoute;
}

export const routes: IRoutes = {
	alarmsActive: {
		path: '/alarms/active',
		component: () => <Alarms cleared={false} />,
	},
	alarmsInactive: {
		path: '/alarms/inactive',
		component: () => <Alarms cleared={true} />,
	},
	unassigned: {
		path: '/tickets/unassigned',
		component: () => <Tickets status="unassigned" />,
	},
	inProgress: {
		path: '/tickets/in-progress',
		component: () => <Tickets status="in_progress" />,
	},
	socClosed: {
		path: '/tickets/soc-closed',
		component: () => <Tickets status="soc_closed" />,
	},
	closed: {
		path: '/tickets/closed',
		component: () => <Tickets status="closed" />,
	},
	silenced: {
		path: '/alarms/silenced',
		component: () => <SilencedAlarms />,
	},
};

export const options: Partial<Options> = {
	queryParamsMode: 'loose',
	allowNotFound: true,
	defaultRoute: 'alarmsActive',
};
