import { TicketStatus } from '@mitie/alarm-api-types';

export function ticketTextStatus(status: TicketStatus) {
	switch (status) {
		case 'unassigned':
			return 'Unassigned';
		case 'in_progress':
			return 'In progress';
		case 'closed':
			return 'Closed';
		case 'soc_closed':
			return 'SOC closed';
	}
}
