import { observable, computed } from 'mobx';
import { NavigationOptions } from 'router5';

import { ILinkData, routes, options } from '../routing/routes';
import { makeMobxRouter } from '../routing/mobxRouterMiddleware';

export interface IFilters {}

class Routes {
	public routes = routes;
	public router = makeMobxRouter(this.routes, options, this);
	private appContentElement: Element | null = null;
	@observable
	public route: {
		name: string | null;
		params: any;
	} = {
		name: null,
		params: {},
	};

	public get routeParams() {
		return this.route.params;
	}

	public get routeName() {
		return this.route.name;
	}

	@computed
	public get routeComponent() {
		if (!this.route.name) {
			return null;
		}

		return this.routes[this.route.name].component;
	}

	@computed
	public get routeLabel() {
		if (!this.route.name) {
			return undefined;
		}

		return this.routes[this.route.name].label;
	}

	public navigate = (linkData: ILinkData, navigateOptions?: NavigationOptions) => {
		const name = linkData.name || this.route.name;

		if (!name) {
			return;
		}

		this.router.navigate(name, {}, navigateOptions || {}, () => {
			if (navigateOptions && navigateOptions.scrollToTop) {
				const container = this.appContentElement || (this.appContentElement = document.querySelector('.app-content'));
				if (container) {
					container.scroll(0, 0);
				}
			}
		});
	};
}

export default Routes;
