import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import {
	TextField,
	Button,
	WithStyles,
	createStyles,
	Theme,
	withStyles,
	FormControlLabel,
	Checkbox,
	DialogContent,
	Dialog,
	DialogTitle,
	Paper,
} from '@material-ui/core';
import { IAlarmApiUpdateTicketRequest } from '@mitie/alarm-api-types';

import { createTicket, updateTicket } from 'src/services/apiService';
import NotificationStore from 'src/store/notifications';

interface ICreateTicketProps extends WithStyles<typeof styles> {
	onClose: () => void;
}

const styles = (theme: Theme) =>
	createStyles({
		background: {
			backgroundColor: theme.palette.background.default,
		},
		newTicketContainer: {
			padding: '1rem 2rem',
		},
	});

@inject('notifications')
@observer
class CreateTicket extends React.Component<ICreateTicketProps> {
	@observable private description = '';
	@observable private assignToSelf = false;
	@observable private ticketId: null | number = null;
	@observable private submitting = false;
	@observable private maximoRef = '';

	private get injected() {
		return (this.props as any) as {
			notifications: NotificationStore;
		};
	}

	@computed
	private get canSubmit() {
		return this.description.length > 0;
	}

	public render() {
		const { classes, onClose } = this.props;

		return (
			<Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth={true}>
				<DialogTitle className={classes.background}>
					{' '}
					{this.ticketId !== null ? `Ticket details: SOC${this.ticketId}` : 'Create ticket'}
				</DialogTitle>
				<DialogContent className={classes.background}>
					<Paper square className={classes.newTicketContainer}>
						<div style={{ display: 'flex', flexGrow: 1 }}>
							<TextField
								label="Description"
								required={true}
								value={this.description}
								onChange={e => (this.description = e.currentTarget.value)}
								style={{ flex: 1 }}
								disabled={this.ticketId !== null}
							/>

							<Button
								variant="contained"
								color="primary"
								disabled={!this.canSubmit || this.submitting || this.ticketId !== null}
								onClick={this.createTicket}
								style={{ marginLeft: '2rem' }}
							>
								Create ticket
							</Button>
						</div>
						{this.ticketId !== null && (
							<div style={{ display: 'flex', flexGrow: 1, marginTop: '2rem' }}>
								<TextField
									label="Maximo work order reference"
									required={true}
									value={this.maximoRef}
									onChange={e => (this.maximoRef = e.currentTarget.value)}
									style={{ flex: 1 }}
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={this.assignToSelf}
											onChange={e => (this.assignToSelf = e.currentTarget.checked)}
										/>
									}
									label="Assign ticket to yourself"
								/>
								<Button
									variant="contained"
									color="primary"
									disabled={!this.maximoRef || this.submitting}
									onClick={this.updateTicket}
									style={{ marginLeft: '2rem' }}
								>
									Save ticket
								</Button>
							</div>
						)}
					</Paper>
				</DialogContent>
			</Dialog>
		);
	}

	private createTicket = async () => {
		try {
			this.submitting = true;
			const { ticket_id } = await createTicket({
				status: 'unassigned',
				description: this.description,
				alarmIds: [],
			});
			this.ticketId = ticket_id;
			this.injected.notifications.addNotification('success', 'Ticket created');
		} catch (e) {
			this.injected.notifications.addNotification('error', 'Failed to create ticket');
		}

		this.submitting = false;
	};

	private updateTicket = async () => {
		if (!this.ticketId) {
			return;
		}

		const { onClose } = this.props;
		const updatedFields: IAlarmApiUpdateTicketRequest = {
			id: this.ticketId,
			maximo_ref: this.maximoRef,
		};

		if (this.assignToSelf) {
			updatedFields.status = 'in_progress';
		}

		try {
			this.submitting = true;
			await updateTicket(updatedFields);
			onClose();
			this.injected.notifications.addNotification('success', 'Ticket updated');
		} catch (e) {
			this.injected.notifications.addNotification('error', 'Failed to update ticket');
			this.submitting = false;
		}
	};
}

export default withStyles(styles)(CreateTicket);
