import { observable, onBecomeObserved, action, onBecomeUnobserved, computed } from 'mobx';
import { Status } from 'src/DataTypes';
import { fetchSilencedAlarms } from 'src/services/apiService';
import { Root } from '.';
import { differenceInMilliseconds } from 'date-fns';
import { ISilencedAlarm } from '@mitie/alarm-api-types';

export interface ISilencedAlarmWithTime extends ISilencedAlarm {
	silencedFor: number;
}

class SilencedAlarms {
	private rootStore: Root;
	@observable public byId: { [id: number]: ISilencedAlarmWithTime } = {};
	private refreshInterval: NodeJS.Timeout | null = null;
	@observable public state: Status = Status.None;

	constructor(rootStore: Root) {
		this.rootStore = rootStore;

		onBecomeObserved(this, 'list', this.fetchSilencedAlarms);
		onBecomeObserved(this, 'list', this.startUpdating);
		onBecomeUnobserved(this, 'list', this.stopUpdating);
	}

	@computed
	public get list() {
		return Object.keys(this.byId)
			.map((id: any) => this.byId[id])
			.filter(a => new Date(a.to) > new Date());
	}

	@action.bound
	public updateSilencedAlarms(alarms: ISilencedAlarm[]) {
		const now = new Date();

		for (const alarm of alarms) {
			this.byId[alarm.id] = {
				...alarm,
				silencedFor: differenceInMilliseconds(now, alarm.to),
			};
		}
	}

	private startUpdating = () => {
		this.refreshInterval = setInterval(() => this.refreshTimes(), 60000);
	};

	private stopUpdating = () => {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval);
		}
	};

	@action.bound
	private refreshTimes() {
		const now = new Date();

		for (const id of Object.keys(this.byId)) {
			const alarm = this.byId[(id as any) as number];

			this.byId[(id as any) as number] = {
				...alarm,
				silencedFor: differenceInMilliseconds(now, alarm.to),
			};
		}
	}

	@action.bound
	private async fetchSilencedAlarms() {
		this.state = Status.Loading;

		try {
			const { silencedAlarms } = await fetchSilencedAlarms();
			this.updateSilencedAlarms(silencedAlarms);

			this.state = Status.Done;
		} catch (e) {
			this.state = Status.Error;
			this.rootStore.notifications.addNotification('error', 'Failed to fetch list of silenced alarms from the server');
		}
	}
}

export default SilencedAlarms;
