import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Routes from 'src/store/routes';
import { Badge, Tabs, Tab } from '@material-ui/core';
import { withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import Alarms from 'src/store/alarms';
import Tickets from 'src/store/tickets';

interface INavBarProps extends WithStyles<typeof styles> {
	className?: string;
}

const styles = (theme: Theme) => ({
	tab: {
		minHeight: '64px',
	},
	badge: {
		padding: `0 ${theme.spacing(2)}px`,
	},
});

@inject('routes', 'alarms', 'tickets')
@observer
class NavBar extends React.Component<INavBarProps> {
	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			alarms: Alarms;
			tickets: Tickets;
		};
	}

	public componentDidMount() {
		const { tickets } = this.injected;

		tickets.fetchTickets(['unassigned', 'in_progress', 'soc_closed']);
	}

	public render() {
		const { classes } = this.props;
		const {
			routes: { routeName },
			alarms,
			tickets,
		} = this.injected;

		return (
			<Tabs value={routeName} variant="scrollable" scrollButtons="auto" onChange={this.handleTabChange}>
				<Tab
					className={classes.tab}
					value="alarmsActive"
					label={
						<Badge className={classes.badge} badgeContent={alarms.notCleared.length} color="secondary">
							Active
						</Badge>
					}
				/>
				<Tab
					className={classes.tab}
					value="alarmsInactive"
					label={
						<Badge className={classes.badge} badgeContent={alarms.cleared.length} color="secondary">
							Inactive
						</Badge>
					}
				/>
				<Tab
					className={classes.tab}
					value="unassigned"
					label={
						<Badge className={classes.badge} badgeContent={tickets.unassignedCount} color="secondary">
							Unassigned
						</Badge>
					}
				/>
				<Tab
					className={classes.tab}
					value="inProgress"
					label={
						<Badge className={classes.badge} badgeContent={tickets.inProgressCount} color="secondary">
							In Progress
						</Badge>
					}
				/>
				<Tab
					className={classes.tab}
					value="socClosed"
					label={
						<Badge className={classes.badge} badgeContent={tickets.socClosedCount} color="secondary">
							SOC Closed
						</Badge>
					}
				/>
				<Tab className={classes.tab} value="closed" label="Closed" />
			</Tabs>
		);
	}

	private handleTabChange = (event: React.ChangeEvent<{}>, value: string) => {
		const { navigate } = this.injected.routes;

		navigate({ name: value });
	};
}

export default withStyles(styles)(NavBar);
