import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { WithStyles, Theme, withStyles } from '@material-ui/core';

import './app.css';
import Routes from './store/routes';
import NotificationStore from './store/notifications';
import { INotification } from './DataTypes';
import { initWebsocket } from './services/apiService';
import HeaderBar from './components/HeaderBar';
import Snackbar from './components/Snackbar';
import User from './store/user';

interface IAppProps extends WithStyles<typeof styles> {}

const styles = (theme: Theme) => ({
	container: {
		height: 'calc(100% - 64px)',
		marginTop: '64px',
	},
	componentContainer: {
		flex: 1,
		display: 'flex',
		height: '100%',
	},
	snackbarContainer: {},
});

@inject('routes', 'notifications', 'user')
@observer
class App extends React.Component<IAppProps> {
	constructor(props: IAppProps) {
		super(props);
		this.injected.routes.router.start();

		initWebsocket();
	}
	private get injected() {
		return (this.props as any) as {
			routes: Routes;
			notifications: NotificationStore;
			user: User;
		};
	}

	public renderRoute = () => {
		const { routeParams, routeComponent } = this.injected.routes;
		if (!routeComponent) {
			return null;
		}

		return routeComponent(routeParams);
	};

	public render() {
		const { notifications, user } = this.injected;
		const { classes } = this.props;

		if (!user.loggedIn) {
			return null;
		}

		return (
			<React.Fragment>
				<div className={classes.snackbarContainer}>
					{notifications.notifications.map(({ id, ...rest }: INotification, index) => (
						<Snackbar key={id} {...rest} onClose={() => notifications.deleteNotification(id)} index={index} />
					))}
				</div>
				<div className={classes.container}>
					<HeaderBar />
					<div className={classes.componentContainer}>{this.renderRoute()}</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(App);
